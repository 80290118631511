import {React} from 'react';
import { supabase } from '../supabase/client';
import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import MenuLateralAdmin from './templates/menuLateralAdmin';
import Container from 'react-bootstrap/Container';
import { Stack ,Button, Row,Col} from 'react-bootstrap';
import {useQR} from '../context/QrContext';
import QrBox from './templates/qr-box'
import {formatDateToDMYHM} from '../utils/utils';
import { Link } from 'react-router-dom';
import  Header from './templates/header';
import { useState } from 'react';
import Form from 'react-bootstrap/Form';
import {useVoiceApi} from '../context/VoiceApiContext';




function Admin(){
    const [activo, setActivo] = useState();
    const { getAllQr,user_id_global,userPlan,getUserPlan} = useQR();
    const {getAllVoices} = useVoiceApi();
    const navigate= useNavigate();
    const [mostrarHabilitados, setMostrarHabilitados] = useState(true);
    const [qr_code,setQrCode]=useState([]);
    console.log("id de usuario global");
    console.log(user_id_global);
    console.log("plan del usuario")
    console.log(getUserPlan());
    const toggleMostrarHabilitados = () => {
        setMostrarHabilitados(true);
    
      };
    const toggleMostrarPendientes = () => {
        setMostrarHabilitados(false);
      }
    
 //  console.log(qr_code)
 useEffect(() => {
    const fetchData = async () => {
      console.log("fetching data");
      const data = await getAllQr();
      setQrCode(data);
      console.log("data",data);
    };
  
    supabase.auth.onAuthStateChange((event, session) => {
      if (!session) {
        navigate('/');
      } else {
        navigate('/admin');
        fetchData(); // Llama a fetchData aquí, dentro del bloque else
      }
    });
  }, []);
    return(
        <>
      <Header>
        </Header>
        
        <Container fluid >
      <Row>
        <Col xs={12} xl={2} className="menu-lateral">
            <MenuLateralAdmin onToggleMostrarHabilitados={toggleMostrarHabilitados} onToggleMostrarPendientes={toggleMostrarPendientes} />
        </Col>
        <Col xl={10} xs={12} className="panel-derecho">

            <Container>
                <Row>
                    <Col xl={3} xs={12}>
                        <Stack gap={1} className="mt-5 m-3">
                        {/* <Button variant="primary" className ="boton blanco" onClick={()=>navigate("qr")}>Crear Nuevo Codigo QR</Button> */}
                        <div className='boton ' onClick={()=>navigate("qr")}> Crear Nuevo Código QR </div>
                        {/* <Link to="/admin/qr" className="btn btn-primary">Crear Nuevo Codigo QR</Link> */}
                        </Stack>
                    </Col>
                    <Col xl={9} xs={12}>
                    <Stack gap={1} className="mt-5 m-3">
                    <Form.Check // prettier-ignore
                                type="switch"
                                id="custom-switch"
                                className='selector'
                                
                                onChange={(e) => setMostrarHabilitados(e.target.checked)}
                                label={mostrarHabilitados ? "Mostrando habilitados" : "Mostrando deshabilitados"}
                                defaultChecked={true}
                            />
                        </Stack>
                    </Col>
                    

                </Row>
                <Row>
                    {/* {
                        qr_code.map(qr => (
                        //    <Col key={qr.id} xl={3} xs={12}>
                        //             <QrBox  titulo={qr.nombre} descripcion="" imagen="" link="" />
                        //     </Col>
                            // <div className="col-12 col-xl-3">
                            //    <QrBox  titulo={qr.nombre} descripcion="" imagen="" link="" />

                            // </div>
                        ))
                    } */}
                     {qr_code.filter(qr => mostrarHabilitados ? qr.status : !qr.status).map((qr) => {
                        return <Col key={qr.id} xl={2} xs={6}>
                                    <QrBox  id={qr.id} titulo={qr.nombre} descripcion="" imagen={process.env.REACT_APP_URL_API+"v1/get-image/"+ qr.url_imagen[0].nombre_imagen} link={qr.url_qr[0].url} fecha_creacion={formatDateToDMYHM(qr.created_at)} />
                            </Col>
                        })}
                 
                 </Row>
            </Container>


        </Col>
      </Row>

    </Container>
    </>
    )
}

export default Admin;
