import react, { useEffect } from 'react';
import {Container,Row,Col,Button,Stack} from 'react-bootstrap';
import MenuLateralAdmin from './templates/menuLateralAdmin';
import QrBox from './templates/qr-box'
import Form from 'react-bootstrap/Form';
import {useState} from 'react';
import { useQR } from '../context/QrContext';
import {useNavigate} from 'react-router-dom';
import { validateUrl } from '../utils/utils';
import Alert from 'react-bootstrap/Alert';
import Header from './templates/header';
import Toast from 'react-bootstrap/Toast';


function AddFolder(){
    const [showToast, setShowToast] = useState(false);

    const toggleShowToast = () => setShowToast(!showToast);


    const navigate= useNavigate();
    // const [nombreQr, setNombreQr] = useState('');
    // const [urlQr, setUrlQr] = useState('');
    // const [show, setShow] = useState(false);

    const [nombreFolder, setNombreFolder] = useState('');
    const [show, setShow] = useState(false);
    const [descripcionFolder, setDescripcionFolder] = useState('');
    const [folder, setFolder] = useState('');
    
    // //nombre validation 
    // const validateNombreQr = (nombre) => {
    //     return nombre.length >= 3;
    // }
    // //url validation
    // const validateUrlQr = (url) => {
    //     return validateUrl(url);
    // }

    //nombre folder validation

    

    const validateNombreFolder = (nombre) => {
        return nombre.length >= 3;
    }
    //descripcion folder validation
    const validateDescripcionFolder = (descripcion) => {
        return descripcion.length >= 3;
    }

    const {createFolder,adding,foldersAll,getAllFolders} =useQR()

    const handleSetFolder = (e) => {
        setFolder(e.target.value)
    }

    const handleCreateFolder = async (e) => {
        e.preventDefault();

        if (!validateNombreFolder(nombreFolder) || !validateDescripcionFolder(descripcionFolder)) {
            // Mostrar un mensaje al usuario o manejar el error de validación
          
            setShow(true)
            return;
        }
        createFolder(nombreFolder,descripcionFolder)
        setShowToast(true)
        setShow(false)
        setNombreFolder('')
        setDescripcionFolder('')
        //navigate('/admin')
    }

    useEffect(() => {
        getAllFolders()
    }, [])
    return(
        <>
        
        
        <Header>
        </Header>
        <Container fluid >
      <Row>
        <Col xs={4} xl={2} className="menu-lateral">
            <MenuLateralAdmin/>
        </Col>
        <Col xl={10} xs={8} className="panel-derecho">

            <Container>
                <Row>
                    <Col xl={2} xs={12}>
                        <Stack gap={1} className="mt-5 m-3">
                        
                        <div className='boton'  onClick={()=>navigate(-1)}> Volver</div>
                        </Stack>
                    </Col>
                </Row>
                <Row>
                    <Col xl={6} xs={12}>
                    <Container>
                    <Form>
                        <Form.Group className="mb-3" >
                            <Form.Label>Nombre</Form.Label>
                            <Form.Control type="text"  id="nombre_qr" placeholder="Nombre de carpeta" value={nombreFolder} onChange={e=>setNombreFolder(e.target.value) } />
                           
                            <Form.Label>Descripcion</Form.Label>
                            <Form.Control  as="textarea"  id="descripcion_folder" placeholder="Descripcion de tu carpeta" value={descripcionFolder} onChange={e=>setDescripcionFolder(e.target.value) } />
                            
                            
                        </Form.Group>
                        <Form.Group className="mb-3" id="crearQrBtn">
                              
                                <div className='boton' disabled={adding} onClick={ handleCreateFolder}>{adding? "Creando Carpeta":"Crear Carpeta"}</div>
                        </Form.Group>
                        {show ? (
                        <Alert variant="danger" onClose={() => setShow(false)} dismissible>
                        <Alert.Heading>Ups, falta algo</Alert.Heading>
                        <p>
                        Ingresa un nombre y una descripcion valida
                        </p>
                    </Alert>):""}
                        
                    <Toast show={showToast} onClose={toggleShowToast} animation={true} bg="success">
          <Toast.Header>
            <img
              src=""
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">Carpeta creada</strong>
            <small></small>
          </Toast.Header>
          <Toast.Body className='text-white'>Listo, creaste la carpeta correctamente</Toast.Body>
        </Toast>
                       
                        </Form>
                    </Container>
                    
                 </Col>
                 </Row>
            </Container>
                            


        </Col>
      </Row>

    </Container>
    </>
    )
}

export  default AddFolder;