
import React, { useEffect, useState } from 'react';
import { BarChart } from '@mui/x-charts/BarChart';

import {Container,Row,Col,Button,Stack} from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import {useNavigate} from 'react-router-dom';
import MenuLateralAdmin from './templates/menuLateralAdmin';
import {useQR} from '../context/QrContext';
import { useParams } from 'react-router-dom';
import Header from './templates/header';
import Modal from 'react-bootstrap/Modal';

//pagina de edición del codigo qr


export default function EditQr(){
    const navigate = useNavigate();
    const { id } = useParams();
    const { qr_code, getDataQr,updateQR,currentQr,foldersAll,getAllFolders} = useQR();
    const [nombreQr, setNombreQr] = useState('');
    const [urlQr, setUrlQr] = useState('');
    const [activo, setActivo] = useState();
    const [folder_id, setFolderId] = useState();

    const [loading, setLoading] = useState(true);
    const [mensajeActualizar, setMensajeActualizar] = useState(false);
    const handleClose = () => setMensajeActualizar(false);
    const handleShow = () => setMensajeActualizar(true);
    const [folder, setFolder] = useState('');
    const [meses, setMeses] = useState([]);
    const [cantidades, setCantidades] = useState([]);



    const handleUpdateQr = async (e) => {
        //console.log("actualizando qr")
        e.preventDefault();
        const result=await updateQR(id, nombreQr, urlQr, activo, folder)
        if(result){
            setMensajeActualizar(true)
        }
        // navigate('/admin')
    }
    const handleSetFolder = (e) => {
        setFolder(e.target.value)
        setFolderId(e.target.value)
    }
    //console.log(folder);
    useEffect(() => {
        //console.log("cargando qr" + id);
        
        // Función para obtener los datos
        const getData = async () => {
            await getDataQr(id);
        };
        
    
        // Llama a la función para obtener los datos
        getData();
    }, []); // Nota: sólo se ejecuta una vez cuando el componente se monta
    
    // Este useEffect se encargará de actualizar el estado local
    // cuando currentQr cambie
    useEffect(() => {
       
        if (currentQr) {
            console.log(currentQr)
            setActivo(currentQr.status);
            setNombreQr(currentQr.nombre);
            setUrlQr(currentQr.url_qr[0].url);
            setFolderId(currentQr.folder_id);
            setFolder(currentQr.folder_id);
            setLoading(false);
            // meses= currentQr.scanned_data.map((data)=>{
            //     const date= new Date(data.created_at)
            //     return date.getMonth()+1
            // });
            // console.log("meses",meses)

            const repeticionesPorMes = currentQr.scanned_data.reduce((acc, item) => {
                const mes = new Date(item.created_at).getMonth() + 1;
                acc[mes] = (acc[mes] || 0) + 1;
                return acc;
              }, {});
              const nombresDeLosMeses = {
                '1': 'Enero',
                '2': 'Febrero',
                '3': 'Marzo',
                '4': 'Abril',
                '5': 'Mayo',
                '6': 'Junio',
                '7': 'Julio',
                '8': 'Agosto',
                '9': 'Septiembre',
                '10': 'Octubre',
                '11': 'Noviembre',
                '12': 'Diciembre'
              };
              setMeses(Object.keys(repeticionesPorMes).map(mes => nombresDeLosMeses[mes]));
              setCantidades(Object.values(repeticionesPorMes))

              console.log(meses);
            // Output: ['Abril', 'Mayo']

              console.log(cantidades);


        }else{
            console.log("no hay qr")
        }
    }, [currentQr]);
    
    useEffect(() => {
        getAllFolders()
    }, [])
    
    return (
<> <Header>
        </Header>
             <Container fluid >
      <Row>
        <Col xs={4} xl={2} className="menu-lateral">
            <MenuLateralAdmin/>
        </Col>
        <Col xl={10} xs={8} className="panel-derecho">

            <Container>
                <Row>
                    <Col xl={2} xs={12}>
                        <Stack gap={1} className="mt-5 m-3">
                        
                        <div className='boton' onClick={()=>navigate(-1)}>Volver</div>
                        </Stack>
                    </Col>
                </Row>
                <Row>
                    <Col xl={6} xs={12}>
                    <Container>
                    <Form onSubmit={handleUpdateQr}>
                        <Form.Group className="mb-3" >
                            <Form.Label>Nombre</Form.Label>
                            <Form.Control type="text"  id="nombre_qr" value={nombreQr}  onChange={(e)=>setNombreQr(e.target.value)} placeholder="Nombre de tu qr" />
                            <Form.Label>URL</Form.Label>
                            <Form.Control type="text" name= "url_qr" value={urlQr} onChange={(e)=>setUrlQr(e.target.value)} placeholder="Url de qr"/>
                            <Form.Label>Activo</Form.Label>
                            <Form.Check // prettier-ignore
                                type="switch"
                                id="custom-switch"
                                {...activo ? {checked: true} : {checked: false}}
                                onChange={(e) => setActivo(e.target.checked)}
                            
                            />
                            <Form.Label>Carpeta</Form.Label>
                            <Form.Select className="mt-3" aria-label="Default select example" value={folder_id} onChange={handleSetFolder}>
                            {folder_id === null && <option>Seleccione una carpeta</option>}
                              {
                              foldersAll.map((folder)=>{

                                return <option key={folder.id} value={folder.id}>{folder.name}</option>
                                
                                })
                            }
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3" id="crearQrBtn">
                               
                                <div className='boton' type="submit" onClick={handleUpdateQr} >Actualizar</div>
                        </Form.Group>
                       
                        

                       
                        </Form>
                    </Container>
                    
                 </Col>
                 </Row>
                 <Container>

                        <Row>

                            <Col xl={6} xs={12}>
                         <div className='contenedor-grafico'>
                              <BarChart
                        xAxis={[{ scaleType: 'band', data: meses}]}
                        series={[{ data: cantidades }]}
                        width={500}
                        height={300}
                        />
                       
                         </div>
                         <div>
                         
                            
                        </div>
                          
                                </Col>

                        </Row>
                 </Container>
                 <Modal  size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered show={mensajeActualizar} onHide={handleClose} 
     >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Actulización Correcta</Modal.Title>
        </Modal.Header>
        <Modal.Body>Se ha actualizado correctamente el QR</Modal.Body>
        <Modal.Footer>
      
          <div className='boton eliminar' onClick={handleClose} >Cerrar</div>
        
        </Modal.Footer>
      </Modal>
            </Container>


        </Col>
      </Row>

    </Container>
    </>
       
    )

}