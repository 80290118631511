import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import {useNavigate} from 'react-router-dom';

export default function MenuLateralAdmin({ onToggleMostrarHabilitados, onToggleMostrarPendientes}){
    const navigate = useNavigate();

    return (
        <>
        <style type="text/css">
        {`
    .btn-flat {
      background-color: balck;
      color: white;
      border-color:none;
    }
    .btn-flat:hover {
        background-color: white;
        color: black;
        border-color:none;
        }

    .btn-xxl {
      padding: 1rem 1.5rem;
      font-size: 1.5rem;
    }
    `}
      </style>

        <Stack gap={1} className="mt-5">
       <div className='boton-menu' onClick={()=>navigate('/admin/')}> <span>Códigos QR</span>  </div>
      {/* <div className=""><div className="botones-menu-lateral"  onClick={onToggleMostrarHabilitados}>Activos</div></div>
      <div className=""><div className="botones-menu-lateral"  onClick={onToggleMostrarPendientes}>Inactivos</div></div> */}
        </Stack>
        
      {/* <div className="separador"></div> */}

      <div className='boton-menu' onClick={()=>navigate('/admin/folders')}> <span>Carpetas</span>  </div>
      {/* <Stack gap={1} className="mt-5">

      <div className=""><div onClick={()=>navigate("/admin/clients")} className="botones-menu-lateral">Clientes</div></div>
      <div className=""><div onClick={()=>navigate("/admin/paymentsClients")} className="botones-menu-lateral">Recordatorios de pagos</div></div>

      </Stack> */}
        

        </>

    )
}