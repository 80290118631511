import React, { useEffect } from 'react';
import {Button}  from '../@/components/ui/button';
import { supabase } from '../supabase/client';
import { useNavigate } from 'react-router-dom';
import { useQR } from 'src/context/QrContext';  
import Spline from '@splinetool/react-spline';
import HeaderNoConected from './templates/header-no-conected';
import Slider from './templates/slider';
import Features from './templates/home/features';

function Home(){
    const navigate= useNavigate();
    const {getUserProfile,setUserProfile,setUserIdGlobal,getUserPlan} = useQR();

    supabase.auth.onAuthStateChange((event, session) => {
        console.log(event, session)
        if(!session){
                // navigate('/login')
        }else{
            /*verificar si el usuario tiene un plan activo*/

            //guardar datos del usuario en el contexto
            console.log("guardando datos del usuario en el contexto"+session)
            setUserIdGlobal(session.user.id);

            navigate('/admin')

        }
    })
 
 
    
    useEffect(() => {
        console.log("usseffect home")
        console.log("llamando a getuserprofile")
        //cargar datos del usuario
        getUserProfile().then((data) => {
            if(!data.length>0){
                console.log("No hay datos del usuario, se creará profile")
                setUserProfile()
                console.log("Profile creado")
            }else{
                console.log("Datos del usuario:", data[0]);
                getUserPlan();
            }
        }).catch((error) => {
            console.log("Error al obtener datos del usuario:", error);
        });
    }, []);

    return(
        <>
        <HeaderNoConected></HeaderNoConected>
        <Slider></Slider>
        <Features></Features>
        {/* <div>
            <Spline scene="https://prod.spline.design/kbR1AmkR4sXchD2h/scene.splinecode" />
        </div> */}
        </>
    )
}

export default Home;