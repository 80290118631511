import React, { useEffect } from 'react';
import {Container,Row,Col,Button,Stack} from 'react-bootstrap';
import MenuLateralAdmin from './templates/menuLateralAdmin';
import QrBox from './templates/qr-box'
import Form from 'react-bootstrap/Form';
import {useState} from 'react';
import { useQR } from '../context/QrContext';
import {useNavigate} from 'react-router-dom';
import { validateUrl } from '../utils/utils';
import Alert from 'react-bootstrap/Alert';
import Header from './templates/header';
import Toast from 'react-bootstrap/Toast';
import BootstrapDatePicker from './templates/bootstrapDatePicker';
import Dropdown from 'react-bootstrap/Dropdown';
import e from 'cors';
import Modal from 'react-bootstrap/Modal';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';


function AddPaymentClient(){
    const [showToast, setShowToast] = useState(false);

    const toggleShowToast = () => setShowToast(!showToast);
    const navigate= useNavigate();
    const [nombreRecordatorio, setNombreRecordatorio] = useState('');
    const [fechaRecordatorio, setFechaRecordatorio] = useState('');
    const [horaRecordatorio, setHoraRecordatorio] = useState('');
    const [contenidoRecordatorio, setContenidoRecordatorio] = useState('');
    const [cliente,setCliente] = useState('');
    const [perocidad,setPeriocidad] = useState('');
    const [notificacion,setNotificacion] = useState(false);
    const [copia,setCopia] = useState(false);
    const [mensajeCrarRecordatorio, setMensajeCrarRecordatorio] = useState(false);

    const handleClose = () => setMensajeCrarRecordatorio(false);
    
    const getClientName=(id)=>{
      clients.map((client)=>{
     
        if(client.id==id){
          console.log(client.name)
          return client.name
        }
      })
    }
    
    const handleSetCliente=(e)=>{
      setCliente(e.target.value);
      console.log("cliente seleccionado",e.target.value)
    }
    const handlePeriocidad=(e)=>{
      setPeriocidad(e.target.value);
      console.log("periocidad seleccionada",e.target.value)
    }
    const handleDateChange = (date) => {
      console.log("Fecha seleccionada:", date);
      setFechaRecordatorio(date);
      // Aquí puedes hacer lo que necesites con la fecha
    }

    const handleAddRecortatorio=(e)=>{
      e.preventDefault();
      console.log("agregando recordatorio")
      //mostrar el valor de todos los campos
      console.log("nombreRecordatorio",nombreRecordatorio);
      console.log("cliente",cliente);
      console.log("contenidoRecordatorio",contenidoRecordatorio);
      console.log("fechaRecordatorio",fechaRecordatorio);
      console.log("perocidad",perocidad);
      console.log("horaRecordatorio",horaRecordatorio);
      console.log("notificacion",notificacion);
      console.log("copia",copia);

      
      //validar que todos los campos esten llenos
      if(nombreRecordatorio=='' || cliente=='' || contenidoRecordatorio=='' || fechaRecordatorio=='' || perocidad=='' || horaRecordatorio==''){
        console.log("faltan campos por llenar")
        return;
      }else{
        console.log("todos los campos estan llenos")
        setMensajeCrarRecordatorio(true);
      }

    }
   
    const traducirNotificacion =(notificacion)=>{
        if(notificacion==true){
          return "Si"
        }else{
          return "No"
      }
    }
    const traducirCopia=(copia)=>{
      if(copia==true){
        return "Si"
      }else{
        return "No"
    }
    }

    const {clients, getAllClients} = useQR();
    
    useEffect(() => {
      getAllClients ();
      setHoraRecordatorio('00:00');
  }, [])

    return (
        <>
        
        
        <Header>
        </Header>
        <Container fluid >
      <Row>
        <Col xs={4} xl={2} className="menu-lateral">
            <MenuLateralAdmin/>
        </Col>
        <Col xl={10} xs={8} className="panel-derecho">
            
            <Container>
                <Row>
                    <Col xl={2} xs={12}>
                        <Stack gap={1} className="mt-5 m-3">
                        
                        <div className='boton'  onClick={()=>navigate(-1)}> Volver</div>
                        </Stack>
                    </Col>
                </Row>
                <Row>
                    <Col xl={6} xs={12}>
                    <Container>
                    <Form>
                        <Form.Group className="mb-3" >
                            <Form.Label>Nombre de tu recordatorio</Form.Label>
                            <Form.Control type="text"  id="nombre_recordatorio" placeholder="Ejemplo: Pago mensual Google Ads" value={nombreRecordatorio} onChange={e=>setNombreRecordatorio(e.target.value) } />
                            
                            <Row>
                              <Col xl={7} xs={12}>
                            <Form.Label>Selecciona tu cliente</Form.Label>
                            <Form.Select aria-label="Default select example" value={cliente} onChange={handleSetCliente}>
                              {clients.map((client)=>{

                                return <option key={client.id} value={client.id}>{client.name}</option>
                                
                                })}
                            </Form.Select>
                            </Col>
                            <Col xl={5} xs={12}>
                              <div></div>
                               <div className='boton centrar-form'  onClick={()=>navigate('/admin/clients/add')}>+ Agregar Cliente</div>
                              </Col>
                            </Row>
                            
                            <Form.Label>Escribe el recordatorio para tu cliente</Form.Label>
                            <Form.Control as="textarea" rows={3} id="contenido_recordatorio" placeholder="Ejemplo: Este es un recordatorio amistoso de pago" value={contenidoRecordatorio} onChange={e=>setContenidoRecordatorio(e.target.value) } />
                            <BootstrapDatePicker onDateChange={handleDateChange} />  
                            <Form.Label>¿Cada cuantos días deseas repetir la notificación? (A partir de la fecha inicial)</Form.Label>
                            <Form.Select aria-label="Default select example" name="periocidad" value={perocidad} onChange={handlePeriocidad}> 
                              <option value="1">Todos los días</option>
                              <option value="2">Cada 5 días</option>
                              <option value="3">Cada 15 días</option>
                              <option value="4">Cada 30 días</option>
                            </Form.Select>

                            <Form.Label>¿En qué horario necesitas que le llegue la notificacion?</Form.Label>
                            <Form.Control type="time"  id="hora_recordatorio" placeholder="Ejemplo: Pago mensual Google Ads" value={horaRecordatorio} onChange={e=>setHoraRecordatorio(e.target.value) } />
                            <br></br>
                            <Form.Check // prettier-ignore
                                  
                                  type="switch"
                                  label="¿Deseas enviarle hoy la notificación al cliente?"
                                  id="disabled-custom-switch"
                                  checked={notificacion}
                                  onChange={(e)=>setNotificacion(e.target.checked)}
                            />
                            <br></br>
                            <Form.Check // prettier-ignore
                                  
                                  type="switch"
                                  label="¿Deseas que te llegue una copia siempre que se le avise al cliente?"
                                  id="disabled-custom-switch"
                                  checked={copia}
                                  onChange={(e) => setCopia(e.target.checked)}
                            />
                            <div>
                              <br></br>
                            <div className='boton'  onClick={handleAddRecortatorio}>Crear Recordatorio</div>
                            </div>
                           




                        </Form.Group>
                        {/* <Form.Group className="mb-3" id="crearQrBtn">
                              
                                <div className='boton' disabled={adding} onClick={ handleCreateQr}>{adding? "Creando QR":"Crear QR"}</div>
                        </Form.Group> */}
                 
                        
                    <Toast show={showToast} onClose={toggleShowToast} animation={true} bg="success">
          <Toast.Header>
            <img
              src=""
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">Qr agregado</strong>
            <small></small>
          </Toast.Header>
          <Toast.Body className='text-white'>Listo, creaste el codigo correctamente</Toast.Body>
        </Toast>
                       
                        </Form>
                    </Container>
                    
                 </Col>
                 </Row>
            </Container>
                            


        </Col>
      </Row>

      <Modal  size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered show={mensajeCrarRecordatorio} onHide={handleClose} 
     >
    <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">¿Está seguro que desa agregar este recordatorio?</Modal.Title>
        </Modal.Header>
        <Modal.Body><br></br>
                Revise bien los siguientes datos ingresados:<br></br>
                <br></br>
                <Card>
                <ListGroup variant="flush">
                      <ListGroup.Item> Nombre del recordatorio: {nombreRecordatorio}</ListGroup.Item>
                      <ListGroup.Item> Cliente: {getClientName(cliente)}</ListGroup.Item>
                      <ListGroup.Item> Contenido del recordatorio: {contenidoRecordatorio}</ListGroup.Item>
                      <ListGroup.Item> Fecha del recordatorio: {fechaRecordatorio}</ListGroup.Item> 
                      <ListGroup.Item> Periocidad: {perocidad}</ListGroup.Item>
                      <ListGroup.Item> Hora del recordatorio: {horaRecordatorio}</ListGroup.Item>
                      <ListGroup.Item> Desea enviarle hoy una notificación: {traducirNotificacion(notificacion)}</ListGroup.Item>
                      <ListGroup.Item> Desea que te llgue una copia: {traducirCopia(copia)}</ListGroup.Item>

                        
                  </ListGroup>
                
                </Card>
               
                <br></br>
        
        </Modal.Body>
        <Modal.Footer>
      
          <div className='boton eliminar' onClick={()=>console.log("si")} >Si</div>
          <div className='boton eliminar' onClick={()=>console.log("no")} >No</div>
        
        </Modal.Footer>
      </Modal>

    </Container>
    </>
    )
}

export default AddPaymentClient;