import validator from 'validator';

export function formatDateToDMYHM(dateString) {
    const date = new Date(dateString);
  
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Los meses son 0-indexados.
    const year = date.getFullYear();
  
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
  
    return `${day}/${month}/${year} ${hours}:${minutes}`;
  }
  
  export function validateUrl(url){
    
    if(validator.isURL(url)){
      return true;
    }else{
      return false;
    }

  }
  export function validateEmail(email){
    if(validator.isEmail(email)){
      return true;
    }else{
      return false;
    }
  }