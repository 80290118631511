import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { supabase } from '../supabase/client';

function CASCallback() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handleCallback = async () => {
      const params = new URLSearchParams(location.search);
      const code = params.get('code');
      
      if (code) {
        try {
          const codeVerifier = localStorage.getItem('codeVerifier');
          
          // Aquí deberías hacer una petición a tu backend para intercambiar el código por un token
          // Este es un ejemplo, deberás implementar este endpoint en tu backend
          const response = await fetch(`${process.env.REACT_APP_API_URL}/cas-token`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ code, codeVerifier }),
          });
          
          const data = await response.json();
          
          if (data.access_token) {
            // Usa el token para autenticar al usuario en tu aplicación
            const { data: authData, error } = await supabase.auth.signInWithIdToken({
              provider: 'cas',
              token: data.access_token,
            });
            
            if (error) throw error;
            
            navigate('/');  // Redirige al usuario a la página principal
          } else {
            throw new Error('No se pudo obtener el token de acceso');
          }
        } catch (error) {
          console.error('Error en el callback de CAS:', error);
          navigate('/login', { state: { error: 'Falló la autenticación con CAS' } });
        }
      }
    };

    handleCallback();
  }, [location, navigate]);

  return <div>Procesando autenticación CAS...</div>;
}

export default CASCallback;