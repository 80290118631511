import {useState } from 'react';
import {supabase} from '../supabase/client';
import {useNavigate} from 'react-router-dom';
import {useEffect} from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { Stack , Row,Col} from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Alert from 'react-bootstrap/Alert';
import { FaGoogle, FaFacebook } from 'react-icons/fa';
import CardLogin from './templates/loginBox';
import Spline from '@splinetool/react-spline';
import Modal from 'react-bootstrap/Modal';





function Login(){
    const navigate = useNavigate();
    const [email, setEmail] = useState();
    const redirectUrl = process.env.NODE_ENV === 'production' 
    ? process.env.REACT_APP_REDIRECT_URL_PROD 
    : process.env.REACT_APP_REDIRECT_URL_DEV;
    const handleClose = () => setMensajeActualizar(false);
    const handleShow = () => setMensajeActualizar(true);
    const [mensajeActualizar, setMensajeActualizar] = useState(false);
    const [enviandoCorreo, setEnviandoCorreo] = useState(false);
    const [enviandoGmail, setEnviandoGmail] = useState(false);
    useEffect(() => {
      setEnviandoCorreo(false)
      setEnviandoGmail(false)
    }, [])

    const handleCASLogin = async () => {
      try {
        const casAuthUrl = 'https://sso.des.uc.cl/cas/oauth2.0/authorize';
        const codeVerifier = generateCodeVerifier();
        const codeChallenge = await generateCodeChallenge(codeVerifier);
        
        const params = new URLSearchParams({
          response_type: 'code',
          client_id: '32231080-760c-4add-9097-7c998ade4420',
          redirect_uri: 'https://www.qrwolf.com/cas-callback',
          code_challenge: codeChallenge,
          code_challenge_method: 'S256',
        });
        
        localStorage.setItem('codeVerifier', codeVerifier);
        
        window.location.href = `${casAuthUrl}?${params.toString()}`;
      } catch (error) {
        console.error('Error initiating CAS login:', error);
      }
  };


    const handleGmailSubmit = async (e) => {
        e.preventDefault();
        setEnviandoGmail(true)
        try {
            //gmail log 
            const result = await supabase.auth.signInWithOAuth({
                provider: 'google',
                options:{
                  acces_type:'offline',
                  prompt:'consent',
                  redirectTo:redirectUrl,
                }
              });
    
            //const result= await supabase.auth.signInWithOtp({email}); 
            console.log(result)
           } catch (error) {
            console.log(error)
           }
    }    
    const validateEmail = (email) => {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
      }
    const handleSubmit = async (e) => {

        if(validateEmail(email)){
          setEnviandoCorreo(true)
          e.preventDefault();
          console.log("enviando correo");
          //ocultar boton y mostrar mensaje
         try {
          //gmail log 
          // const result = await supabase.auth.signInWithOAuth({
          //     provider: 'google',
          //   });
  
          const result= await supabase.auth.signInWithOtp({email}); 
          console.log(result)
         } catch (error) {
          console.log(error)
         }
        }else{
          setMensajeActualizar(true)
        }
     

    }
    function generateCodeVerifier() {
      const array = new Uint32Array(56/2);
      window.crypto.getRandomValues(array);
      return Array.from(array, dec => ('0' + dec.toString(16)).substr(-2)).join('');
  }

  async function generateCodeChallenge(verifier) {
      const hash = await crypto.subtle.digest('SHA-256',
          new TextEncoder().encode(verifier));
      return btoa(String.fromCharCode(...new Uint8Array(hash)))
          .replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_');
  }


    return(
        <>



        
        <div className="Auth-form-container">
        <form className="Auth-form" onSubmit={handleSubmit}>
          <div className="">
          <Container className="contenedor-texto-home font-sans	mt-3">
                    <h1> Login</h1>
                   
</Container>
            <div className="form-group mt-3">
              <label>Ingresa tu correo y recibe un link directopara entrar</label>
              <input
                type="email"
                className="form-control mt-3"
                placeholder="info@misterwolf.cl"  onChange = {(e) => setEmail(e.target.value)} 
              /> 
            </div>
            
            <div className="d-grid gap-2 mt-3 ingresar-contenedor ">
              {
                enviandoCorreo ?(
                  <div className="contenedor-spinner d-flex justify-content-center">

                 
                  </div>
                ):(
                  <div  className="boton centrar" onClick={handleSubmit}>Enviar correo de acceso</div>
                )
              }
             
            </div> 
            <div className="contenedor-texto-home separador-superior font-sans mt-5 ">
              <label>O inicia sesión con CAS</label>             
              <div className='boton-login mt-4' onClick={handleCASLogin}>Iniciar sesión con CAS</div>
            </div>
            
           
           
                  
            {/* <div className="login-google">
                <Stack gap={1} className="mt-5">
                    <div  className="boton" onClick={handleGmailSubmit}>Ingresar con Gmail</div>
                </Stack>
            </div> */}
            { enviandoCorreo ?(

                <div  className="resultado">
                    <Alert key="success" variant="success">
           En unos minutos te llegará un correo, con el cual podrás ingresar directamente
        </Alert>
                <span></span>

                </div>

            ):(
                <div></div>
            )

              
            }
          </div>
          
          <div className="contenedor-texto-home separador-superior font-sans	mt-5 ">
       
          
                  <label>Puedes entrar directamente mediante tu cuenta Gmail</label>             
                <div className='boton-login mt-4' onClick={handleGmailSubmit}>  <FaGoogle className="" /> </div>
             
        
              {/* <div className="mx-2">
              <div className='boton-login' onClick={handleGmailSubmit}>  <FaFacebook className="" /> </div>
               
              </div> */}
            </div>

     
         
        </form>
      </div>
      <Modal  size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered show={mensajeActualizar} onHide={handleClose} 
     >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>Debe ingresar un correo electrónico válido</Modal.Body>
        <Modal.Footer>
      
          <div className='boton eliminar' onClick={handleClose} >Cerrar</div>
        
        </Modal.Footer>
      </Modal>
    
        
        </>
    )
    
}

export default Login;