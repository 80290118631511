import React from "react";
import {Form} from 'react-bootstrap';

class bootstrapDatePicker extends React.Component{
    handleChange = (event) => {
        // Llama a la función pasada como prop desde el componente padre
        this.props.onDateChange(event.target.value);
    }

    render(){
 
        return(
            <div>
                <div className="row">
                    <div className="col-md-12">
                        <Form.Group controlId="fecha_notificacion">
                            <Form.Label>A partir de qué fecha, necesitas que empiece a llegar las notificaciones al cliente</Form.Label>
                            <Form.Control  onChange={this.handleChange}  type="date" name="fecha_notificacion" placeholder="Date of Birth" />
                        </Form.Group>
                    </div>
                </div>
            </div>
        )
    }
}

export default bootstrapDatePicker;