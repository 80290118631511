import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import {supabase} from '../../supabase/client';
import { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import DropdownButton from 'react-bootstrap/DropdownButton';
import { Dropdown,Image } from 'react-bootstrap';

function Header(){
  const [show, setShow] = useState(false);

    return(
        <>
        <style type="text/css">
        {`
        .bg-body-tertiary {
            background-color: white!important;
            z-index: 1;
        }
        `}
        </style>
        

    <Container fluid className='header-no-conected' >

        <Row>

                <Col xs={4} xl={4}>
                <Navbar.Brand href="/">
                    <Image src="/img/site/logo_mrwolf.png"  className="logo-img" />

                </Navbar.Brand>
                </Col>
                <Col xs={4} xl={4}>
                
                </Col>
                <Col xs={4} xl={4}>
                <Navbar expand="xl" className="">
      <Container fluid >
       
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav>
            {/* <Nav.Link href="#home">HOME</Nav.Link> */}
    

          <Dropdown show={show} onToggle={setShow}>
            <Dropdown.Toggle as={Image} src="https://picsum.photos/50" roundedCircle className="dont-close avatar-img" />

            <Dropdown.Menu align="right">
                <Dropdown.Item href="#perfil">Perfil</Dropdown.Item>
                <Dropdown.Item  onClick ={() => supabase.auth.signOut()} >Cerrar Sesión</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
            

            
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
                </Col>



        </Row>
       
        
      </Container>
    </>
    )
}

export default Header;