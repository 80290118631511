import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import {React} from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../../supabase/client';
import { useQR } from '../../context/QrContext';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { useState } from 'react';


function ClientBox({id,nombre,correo,fecha_creacion}){
    const {clients,getDataClient} = useQR();
    const [mensajeBorrar, setMensajeBorrar] = useState(false);
    const handleClose = () => setMensajeBorrar(false);
    const handleShow = () => setMensajeBorrar(true);
    const deleteClient= async (e)=>{

        e.preventDefault();
        handleClose()

    }
    // const deleteQr = async (e) => {
    //     e.preventDefault();
    //     handleClose();
    //     console.log("borrando qr")
    //     handleDeleteQr(id);
    // }
    const navigate= useNavigate();

    useEffect(() => {

    }, [])

    return (
        
        
        <>
       
        <style>
        {` 
        .boton{
            margin: 2px;
            border:none;
            border-radius: 4px;
            background-color: #000000;

        }
       
        `}
        </style>
            <Card style={{ width: '100%'}} className="m-3">
                {/* <Card.Img variant="top" src={imagen} /> */}
                <Card.Body>
                    <Card.Title>{nombre}</Card.Title>
                    <Card.Text>
                  
                    Correo: {correo}<br></br>
                    Creación: {fecha_creacion}
                    </Card.Text>
                    
                    {/* <Button variant="primary" onClick={()=>navigate('/admin/qr/'+id)} className='boton editar negro'>Editar</Button> */}
                    <div onClick={handleShow}className='boton eliminar negro'>Eliminar </div>
              
                    <div onClick={()=>navigate('/admin/clients/'+id)}  className='boton eliminar negro'>Editar </div>

                  
                </Card.Body>
                </Card>
                <Modal  size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered show={mensajeBorrar} onHide={handleClose} 
     >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Eliminando Cliente</Modal.Title>
        </Modal.Header>
        <Modal.Body>¿Estás seguro que deseas eliminar a este cliente?</Modal.Body>
        <Modal.Footer>
      
          <div className='boton eliminar' onClick={handleClose} >Cerrar</div>
          <div className='boton eliminar' onClick={deleteClient} >Eliminar</div>
        
        </Modal.Footer>
      </Modal>
        </>
    )

}

export default ClientBox;