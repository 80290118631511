import './App.css';
import {useEffect} from 'react';
import Login from './pages/login';
import Home from './pages/Home';
import Admin from './pages/Admin.js';
import Header from './pages/templates/header';
import NotFound from './pages/NotFound.js';
import {Routes,Route,useNavigate} from 'react-router-dom';
import {supabase} from './supabase/client';
import AddQr from './pages/AddQr';
import { QrContextProvider } from './context/QrContext';
import { VoiceApiProvider } from './context/VoiceApiContext';
import EditQr from './pages/EditQr';
import { useState } from 'react';
import AdminClients from './pages/AdminClients';
import AdminPaymentsClients from './pages/AdminPaymentsClients';
import AddClient from './pages/AddClient';
import AddPaymentClient from './pages/AddPaymentClient';
import Folders from './pages/Folders';
import AddFolder from './pages/AddFolder';
import Account from './pages/Account';
import Folder from './pages/Folder';
import SampleGenerator from './pages/SampleGenerator';
import { SpeedInsights } from '@vercel/speed-insights/react';
import CASCallback from './pages/CASCallback';



function App() {


  return (
    <div className="App">  
     
      <QrContextProvider>

        <VoiceApiProvider>
   
      <Routes>
          <Route path="/" element={<Home />} />
          <Route  path="/login" element={<Login/>}/>
          <Route  path="/admin" element={<Admin/>}/>
          <Route  path="/admin/qr" element={<AddQr/>}/>
          <Route  path="/admin/qr/:id" element={<EditQr/>}/>
          <Route path ="/admin/clients" element={<AdminClients/>}/>
          <Route path ="/admin/clients/:id" element={<EditQr/>}/>
          <Route path="/admin/clients/add" element={<AddClient/>}/>
          <Route path="/admin/paymentsClients" element={<AdminPaymentsClients/>}/>
          <Route path="/admin/paymentsClients/add" element={<AddPaymentClient/>}/>
          <Route path="/admin/folders" element={<Folders/>}/>
          <Route path ="/admin/folders/add" element={<AddFolder/>}/>
          <Route path ="/admin/folders/:id" element={<Folder/>}/>
          <Route path ="/sample-generator" element={<SampleGenerator/>}/>
          <Route path="/cas-callback" element={<CASCallback />} />

          <Route path ="/admin/account" element={<Account/>}/>

          <Route path="*" element={<NotFound/>} />  
      </Routes>
      </VoiceApiProvider>
      </QrContextProvider>

      <SpeedInsights />
    </div>
    
  );
}

export default App;
