import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import {React} from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../../supabase/client';
import { useQR } from '../../context/QrContext';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';


function QrBox({id,titulo, descripcion, imagen, link, fecha_creacion}){
    const {qr_code, handleDeleteQr,getDataQR} = useQR();
    const [mensajeBorrar, setMensajeBorrar] = useState(false);
    const handleClose = () => setMensajeBorrar(false);
    const handleShow = () => setMensajeBorrar(true);

    const deleteQr = async (e) => {
        e.preventDefault();
        handleClose();
        console.log("borrando qr")
        handleDeleteQr(id);
    }
    const navigate= useNavigate();

    useEffect(() => {

    }, [])
    return (
        
        
        <>
       
        <style>
        {` 
        .boton{
            margin: 2px;
            border:none;
            border-radius: 4px;
            background-color: #000000;

        }
       
        `}
        </style>
            <Card style={{ width: '100%'}} className="m-3 qr-box-container">
                <Card.Img variant="top" src={imagen} />
                <Card.Body>
                    <Card.Title className='titulo'>{titulo}</Card.Title>
                    <Card.Text className='descripcion'>
                    {descripcion}
                    Link: {link}<br></br>
                    Creación: {fecha_creacion}
                    </Card.Text>
                    
                    {/* <Button variant="primary" onClick={()=>navigate('/admin/qr/'+id)} className='boton editar negro'>Editar</Button> */}
                    <Container>
                      <Row>
                        <Col xl={12}>
                        <div onClick={()=>navigate('/admin/qr/'+id)}  className='boton  negro'>Editar </div>
                        </Col>
                        <Col xl={12}>
                        <div onClick={handleShow}className='boton  negro'>Eliminar </div>
                        </Col>
                      </Row>
                    </Container>
                      
              
                   

                  
                </Card.Body>
                </Card>
                <Modal  size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered show={mensajeBorrar} onHide={handleClose} 
     >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Eliminando Código QR</Modal.Title>
        </Modal.Header>
        <Modal.Body>¿Estás seguro que deseas eliminar este código?</Modal.Body>
        <Modal.Footer>
      
          <div className='boton eliminar' onClick={handleClose} >Cerrar</div>
          <div className='boton eliminar' onClick={deleteQr} >Eliminar</div>
        
        </Modal.Footer>
      </Modal>
        </>
    )
}

export default QrBox;