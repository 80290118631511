import React from 'react';
import Header from './templates/header';
import { Col, Container, Row } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import { Form } from 'react-bootstrap';





const Account = () => {
    return (
        <>
          <Header>
        </Header>
            <Container className='contenedor-account'>
                    <Row>
                            <Col xl={6} xs={12}>
                   

                                <h3>INFORMACIÓN DE LA CUENTA</h3>
                                <div className='separador'> </div>
                                <div className='input'>
                                    <span><b>Nombre:</b></span>
                                   <span>Juan Perez</span>
                                </div>
                                <div className='input'>
                                    <span> <b>ID de cliente :</b></span>
                                   <span>23123123</span>
                                </div>
                                <div className='input'>
                                    <span><b> Fecha de registro:</b></span>
                                   <span>23/03/24</span>
                                </div>
                                <div className='input'>
                                    <span><b>Tipo de Cuenta:</b></span>
                                    <span>Profesional (anual)</span>
                                </div>
                                
                              

                            </Col>
                            <Col xl={6} xs={12}>
                   

                   <h3>USO DE LA CUENTA</h3>
                   <div className='separador'> </div>
                   <div className='input'>
                       <span><b>Escaneos totales:</b></span>
                      <span>366079 / Ilimitado</span>
                   </div>
                   <div className='input'>
                       <span> <b>Códigos QR Dinámicos:</b></span>
                      <span>142 / 250</span>
                   </div>
                   
                   
                 

               </Col>

                    </Row>

            </Container>

            <Container className='contenedor-account form-contact'>
                    <Row>
                            <Col xl={6} xs={12}>
                   

                                <h3>CONTACTO DE LA CUENTA PRINCIPAL</h3>

                                <div className='separador'> </div>
                               <span>Por favor, proporciona tus datos de contacto abajo.
Para cambiar tu dirección de facturación y/o método de pago, consulta Facturación.</span>
                                <div className='input mt-4'>
                                    <span><b>Nombre:</b></span>
                                    <Form.Control size="text" type="text" placeholder="Nombre" />

                                </div>
                                <div className='input'>
                                    <span> <b>Teléfono:</b></span>
                                    <Form.Control size="text" type="text" placeholder="Telefono" />
                                </div>
                                <div className='input'>
                                    <span><b> Empresa:</b></span>
                                    <Form.Control size="text" type="text" placeholder="Empresa" />
                                </div>
                                <div className='input'>
                                    <span><b>Dirección:</b></span>
                                    <Form.Control size="text" type="text" placeholder="Dirección" />
                                </div>
                                <div className='input'>
                                    <span><b>Ciudad:</b></span>
                                    <Form.Control size="text" type="text" placeholder="Dirección" />
                                </div>
                                <div className='input'>
                                    <span><b>País:</b></span>
                                    <Form.Control as="select" size="text" placeholder="País">
                                        <option>Argentina</option>
                                        <option>Brasil</option>
                                        <option>Chile</option>
                                        <option>Colombia</option>
                                        <option>Ecuador</option>
                                        <option>México</option>
                                        <option>Perú</option>
                                        <option>Uruguay</option>
                                        <option>Venezuela</option>
                                    </Form.Control>
                                </div>
                                <div className='input'>
                                    <span><b>Página Web:</b></span>
                                    <Form.Control size="text" type="text" placeholder="Dirección" />
                                </div>
                                
                              

                            </Col>
                            

                    </Row>

            </Container>

        </>
     
    );
}

export default Account;