import React from 'react';
import {Navbar,Container,Nav,Dropdown,Image} from 'react-bootstrap';
import {Row,Col} from 'react-bootstrap';

const HeaderNoConected = () => {
    return (
       
      <Container fluid className='header-no-conected' >

        <Row>

                <Col xs={4} xl={4}>
                <Navbar.Brand href="#home">
                    <Image src="/img/site/logo_mrwolf.png"  className="logo-img" />

                </Navbar.Brand>
                </Col>
                <Col xs={4} xl={4}>
                
                </Col>
                <Col xs={4} xl={4}>
                <Navbar expand="xl" className="nav-header">
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="">
                             { <Nav.Link href="/login">Login</Nav.Link> }

                         </Nav>
                    </Navbar.Collapse>
                 </Navbar>
                </Col>



        </Row>
       
        
      </Container>
   
    );
};

export default HeaderNoConected;