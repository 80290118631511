import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Container, Row, Col, Button, Stack } from "react-bootstrap";
import Header from "./templates/header";
import MenuLateralAdmin from "./templates/menuLateralAdmin";
import { useState } from "react";
import {useQR} from '../context/QrContext';
import QrBox from './templates/qr-box';
import {formatDateToDMYHM} from '../utils/utils';



export default function Folder() {

    const navigate= useNavigate();
    const {qr_codes,getAllQrByFolder,getFolderData,folderInfo} = useQR();

    const {id}= useParams();
    const qrCodes=[];
    const [folderName, setFolderName] = useState('');
    const [folderDate, setFolderDate] = useState('');


    
    useEffect(() => {

        if(folderInfo && folderInfo[0]){
           
            setFolderName(folderInfo[0].name);
            setFolderDate(formatDateToDMYHM(folderInfo[0].created_at));

        }
       
        
    }
    , [folderInfo])
    
    useEffect(() => {
        getAllQrByFolder(id);
        getFolderData(id);
    }, [id])
    

    return (
       

       <>
       
       <Header>
        </Header>
        
        <Container fluid >
      <Row>
        <Col xs={4} xl={2} className="menu-lateral">
            <MenuLateralAdmin/>
        </Col>
        <Col xl={10} xs={8} className="panel-derecho">

            <Container>
                <Row>
                    <Col xl={3} xs={12}>
                        <Stack gap={1} className="mt-5 mb-2">
                        {/* <Button variant="primary" className ="boton blanco" onClick={()=>navigate("qr")}>Crear Nuevo Codigo QR</Button> */}

                        <div className='boton' onClick={()=>navigate(-1)}> Atrás</div>

                    
                      


                        {/* <Link to="/admin/qr" className="btn btn-primary">Crear Nuevo Codigo QR</Link> */}
                        </Stack>
                    </Col>
                </Row>
                
            </Container>
            <Container className="contenedor-detalle">
                       <Row>
                                <Col xl={12} xs={12}>
                                         
                                  <ul>
                                        <li>Nombre: {folderName}</li>
                                        <li>Fecha de creación: {formatDateToDMYHM(folderDate)}</li>

                                  </ul>
                            </Col>
                        </Row>
    
            </Container>
            <Container>

            <Row>
                    {/* {
                        qr_code.map(qr => (
                        //    <Col key={qr.id} xl={3} xs={12}>
                        //             <QrBox  titulo={qr.nombre} descripcion="" imagen="" link="" />
                        //     </Col>
                            // <div className="col-12 col-xl-3">
                            //    <QrBox  titulo={qr.nombre} descripcion="" imagen="" link="" />

                            // </div>
                        ))
                    } */}
                     {qr_codes.map((qr) => {
                        return <Col key={qr.id} xl={2} xs={6}>
                                    <QrBox  id={qr.id} titulo={qr.nombre} descripcion="" imagen={process.env.REACT_APP_URL_API+"v1/get-image/"+ qr.url_imagen[0].nombre_imagen} link={qr.url_qr[0].url} fecha_creacion={formatDateToDMYHM(qr.created_at)} />
                            </Col>
                        })}
                 
                 </Row>
            </Container>


        </Col>
      </Row>

    </Container>
       
       </>
    )
}

