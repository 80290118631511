import react from 'react';
import { Stack,Container,Row,Col } from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
import MenuLateralAdmin from './templates/menuLateralAdmin';
import Header from './templates/header';
import { useQR } from '../context/QrContext';
import { useEffect } from 'react';


function AdminPaymentsClients(){
    const navigate= useNavigate();


    return (
        <>
        <Header>
          </Header>
          
          <Container fluid >
        <Row>
          <Col xs={4} xl={2} className="menu-lateral">
              <MenuLateralAdmin/>
          </Col>
          <Col xl={10} xs={8} className="panel-derecho">
  
              <Container>
                  <Row>
                      <Col xl={3} xs={12}>
                          <Stack gap={1} className="mt-5 m-3">
                          {/* <Button variant="primary" className ="boton blanco" onClick={()=>navigate("qr")}>Crear Nuevo Codigo QR</Button> */}
                          <div className='boton ' onClick={()=>navigate("add")}> Nuevo recordatorio de pago </div>
                          {/* <Link to="/admin/qr" className="btn btn-primary">Crear Nuevo Codigo QR</Link> */}
                          </Stack>
                      </Col>
                  </Row>
                 
              </Container>
  
  
          </Col>
        </Row>
  
      </Container>
      </>
    )

}

export default AdminPaymentsClients;