
import { createContext, useContext, useState } from 'react';
import axios from 'axios';

export const VoiceApiContext = createContext();

export const useVoiceApi = () => {
  const context = useContext(VoiceApiContext);

  if(!context) {
    throw new Error('useVoiceApi must be used within <VoiceApiProvider>');
  }

  return context;
}

export const VoiceApiProvider = ({children}) => {

  const [voiceApi, setVoiceApi] = useState();

  const getAllVoices = async () => {
    const options = {
      method: 'GET',
      url: 'https://api.elevenlabs.io/v1/voices',
      headers: {
        'xi-api-key': process.env.ELEVEN_LABS_API_KEY  
      }
    };

    try {
      const response = await axios.request(options);
      console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <VoiceApiContext.Provider value={{getAllVoices}}>
      {children}
    </VoiceApiContext.Provider>
  )
}