import HeaderNoConected from './templates/header-no-conected';
import { Container } from 'react-bootstrap';
import { Form, Button } from 'react-bootstrap';



const SampleGenerator = () => {

    return (
        <>
            <HeaderNoConected></HeaderNoConected>
            <Container >
                <div className='contenedor-texto-home mt-5 text-center'>
                <h1>Generador de Samples</h1>
                <span>
                    <p>Genera samples de audio a partir de texto</p>
                    <p>Ingresa el texto y presiona el botón "Generar"</p>
                    <p>El modelo generará un sample de audio a partir del texto ingresado</p>
                    <p>El sample generado se reproducirá automáticamente</p>
                </span>
                
                <Form className='formulario-samples'>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Ingresa como quieres que sea tu sample</Form.Label>
                        <Form.Control type="text" placeholder="Ejemplo: lo-fi music with a soothing melody, some regaeton in the midle " />
                        <Form.Text className="text-muted">
                        Nunca compartiremos tu información personal con nadie.
                        </Form.Text>
                    </Form.Group>

                   
                    <Form.Group className="mb-3" controlId="formBasicCheckbox">
                        <Form.Check type="checkbox" label="Acepto T&C" />
                    </Form.Group>
                    <Button variant="primary" type="submit">
                        Generar
                    </Button>
                    </Form>
                
                </div>
                
    
            </Container>
        </>
    )
    
}


export default SampleGenerator;