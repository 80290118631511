import React, { useEffect } from 'react';
import { Stack,Container,Row,Col } from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
import MenuLateralAdmin from './templates/menuLateralAdmin';
import Header from './templates/header';
import { useQR } from '../context/QrContext';
import QrBox from './templates/qr-box'
import ClientBox from './templates/client-box'
import {formatDateToDMYHM} from '../utils/utils';



function AdminClients(){
    const navigate= useNavigate();
    const {clients, getAllClients} = useQR();
    useEffect(() => {
        getAllClients ();
   
    }, [])
    return (
        <>
        <Header>
          </Header>
          
          <Container fluid >
        <Row>
          <Col xs={4} xl={2} className="menu-lateral">
              <MenuLateralAdmin/>
          </Col>
          <Col xl={10} xs={8} className="panel-derecho">
  
              <Container>
                  <Row>
                      <Col xl={3} xs={12}>
                          <Stack gap={1} className="mt-5 m-3">
                          {/* <Button variant="primary" className ="boton blanco" onClick={()=>navigate("qr")}>Crear Nuevo Codigo QR</Button> */}
                          <div className='boton ' onClick={()=>navigate("add")}> Agregar Nuevo Cliente </div>
                          {/* <Link to="/admin/qr" className="btn btn-primary">Crear Nuevo Codigo QR</Link> */}
                          </Stack>
                      </Col>
                  </Row>
                  <Row>
                  {clients.map((client)=>{
                        return <Col key={client.id} xl={3} xs={6}>
                                    <ClientBox  id={client.id} nombre={client.name} correo={client.mail} fecha_creacion={formatDateToDMYHM(client.created_at)} />
                            </Col>
                  })}
                   </Row>
              </Container>
  
  
          </Col>
        </Row>
  
      </Container>
      </>
    )

}

export default AdminClients;