import {createContext,useContext,useState} from 'react';
import {supabase} from '../supabase/client';
import { createClient} from './ClientsContext'; // Importa la función

export const QrContext = createContext();
export const useQR=()=> {
    const context= useContext(QrContext)
    if(!context) throw new Error("useTask debe estar dentro del proveedor QrContext")
    return context
};

export const QrContextProvider = ({children}) => {
    
    const [qr_code,setQR]= useState([]) 
    const [qr_codes,setQrCodes]= useState([])
    const [clients,setClients]= useState([])
    const [foldersAll,setFolders]= useState([])
    const [folderInfo,setFoldersData]= useState([])	
    // const [userPlan,setUserPlan]= useState([])



    const [adding,setAdding]= useState(false)
    const [currentQr, setCurrentQr] = useState(null);
    const [user_id_global, setUserId] = useState(null);

    const setUserIdGlobal = (user_id) => {
        setUserId(user_id);
    }

    /* USER ACCOUNT*/

    
    const setUserProfile = async () => {
        //console.log("Insertando Profile")
        const session_user= supabase.auth.getSession();
        const user_id= (await session_user).data.session.user.id
    

        try {
            const result = await supabase.from('profiles').insert({
                user_id: user_id,
            }).select().then((result) => {
                //console.log(result);
                if(result.status==201){
                    //console.log("se creo el perfil")
                }
            }).catch((error) => {   
                //console.log(error);
            });
    
            const planResult = await supabase.from('user_plan').insert({
                id_user: user_id,
                id_plan: 1,
            }).select().then((result) => {
                //console.log(result);
                if(result.status==201){
                    //console.log("se creo el plan")
                }
            }).catch((error) => {   
                //console.log(error);
            })

            return result;
            /* retornar un json con el nombre del plan , el id del usuario más los creditos totales del plan*/



        }catch(error){
        


        }

    }
    const getUserPlan = async () => {
        try {
          // Obtener la sesión del usuario
          const sessionUser = await supabase.auth.getSession();
          const userId = sessionUser.data.session.user.id;
      
          // Obtener el plan del usuario
          const { data, error } = await supabase
          .from('user_plan')
          .select('id_plan, plan:plan(id, name)') // Cambio aquí
          .eq('id_user', userId)
          .single();
      
          if (error) {
            console.error('Error al obtener el plan del usuario:', error);
            return null;
          } else {
            //console.log('Datos devueltos:', data);
            if (data && data.plan) {
              //console.log('Se obtuvo el plan del usuario:', data.plan.name);
              return { id_plan: data.id_plan, name: data.plan.name }; // Devolver el id_plan y el nombre del plan
            } else {
              //console.log('No se encontró el plan del usuario');
              return null;
            }
          }
        } catch (error) {
          console.error('Error al obtener el plan del usuario:', error);
          return null;
        }
      };
    const getUserProfile = async () => {
        //console.log("obteniendo perfil");
        try {
            const session_user = await supabase.auth.getSession();
            const user_id = session_user.data.session.user.id;
            const result = await supabase.from('profiles').select('*').eq("user_id", user_id);
            
            //console.log(result);
            if (result.status === 200) {
                //console.log("resultado de User Profile");
                //console.log(result.data);
                return result.data; // Asegurarse de devolver el resultado deseado aquí
            } else {
                // Manejar la situación cuando el status no es 200
                // Tal vez quieras devolver algo específico o lanzar un error
                throw new Error("No se pudo obtener el perfil del usuario correctamente.");
            }
        } catch (error) {
            //console.log(error);
            // Decidir qué hacer en caso de error
            // Podrías devolver null, un objeto vacío {}, o lanzar el error
            throw error; // o return null; o return {};
        }
    }

    /* CLIENTS*/
    const getAllClients = async () => {
        //console.log("obteniendo clientes")
        const session_user= supabase.auth.getSession();
        const user_id= (await session_user).data.session.user.id
        const result = await supabase.from('user_clients').select('*').eq("user_id",user_id).is("status",true).then((result) => {
               //console.log(result);
                if(result.status==200){
                    setClients(result.data)
    
                }
            }).catch((error) => {
                //console.log(error);
            });
    }
    /* FOLDERS*/
    const getFolderData= async(id_folder)=>{
        //console.log("obteniendo folder")
        const session_user= supabase.auth.getSession();
        const user_id= (await session_user).data.session.user.id
        const result = await supabase.from('folders').select('*').eq("user_id",user_id).eq("id",id_folder).then((result) => {
                //console.log(result);
                if(result.status==200){
                    setFoldersData(result.data)
                }
            }).catch((error) => {
                //console.log(error);
            });
    }
    /* QR */
    const getAllQr = async () => {
        try {
          const session_user = supabase.auth.getSession();
          const user_id = (await session_user).data.session.user.id;
          const result = await supabase
            .from('qr_codes')
            .select('*,url_qr:url_qr(url),url_imagen:url_qr(nombre_imagen)')
            .eq('user_id', user_id)
            .is('eliminado', false);
      
          if (result.status === 200) {
            return result.data;
          } else {
            console.error('Error al obtener los códigos QR:', result.error);
            return [];
          }
        } catch (error) {
          console.error('Error al obtener los códigos QR:', error);
          return [];
        }
      };

    const getAllQrByFolder= async(id_folder)=>{
        //console.log("obteniendo qr")
        const session_user= supabase.auth.getSession();
        const user_id= (await session_user).data.session.user.id
        const result = await supabase.from('qr_codes').select('*,url_qr:url_qr(url),url_imagen:url_qr(nombre_imagen)').eq("user_id",user_id).eq("folder_id",id_folder).then((result) => {
               //console.log(result);
                if(result.status==200){
                    setQrCodes(result.data)
                   // setQR(result.data)
  
                }
            }).catch((error) => {
                //console.log(error);
        });
        //obtiene el nombre de las carpetas para cada codigo qr
        
    }


    const getDataQr = async (id_qr) => {
        const session_user = supabase.auth.getSession();
        const user_id = (await session_user).data.session.user.id;
        
        try {
          const { data, error } = await supabase
            .from('qr_codes')
            .select(`
              nombre,
              status,
              url_qr(url),
              total_scanned:scaned!scaned_qr_code_id_fkey(count),
              scanned_data:scaned!scaned_qr_code_id_fkey(*)
            `)
            .eq('user_id', user_id)
            .eq('id', id_qr)
            .single();
      
          if (error) {
            console.error('Error al obtener datos:', error);
            return null;
          }
      
          if (data) {
            console.log("Se obtuvieron los datos del código QR y scanned");
            console.log(data);
            setCurrentQr(data);
            setQR(data);
          }
      
          return data;
        } catch (error) {
          console.error('Error al obtener datos:', error);
          return null;
        }
      };

    const updateQR= async (id_qr,nombreQr,url_qr,activo,folder)=>{
        //console.log("actualizando qr")
        const session_user= supabase.auth.getSession();
        const user_id= (await session_user).data.session.user.id
        const resultQrCodes = await supabase
        .from('qr_codes')
        .update({ nombre: nombreQr ,status:activo,folder_id:folder})
        .eq('user_id', user_id)
        .eq('id', id_qr);
    
        // Verificar si la actualización fue exitosa
        if (resultQrCodes.error) {
            console.error("Error al actualizar qr_codes:", resultQrCodes.error);
            return;
        }
        
        // 2. Actualizar el campo 'url' en la tabla 'url_qr'
        const resultUrlQr = await supabase
            .from('url_qr')
            .update({ url: url_qr })
            .eq('qr_id', id_qr); // Asumiendo que 'qr_id' es la columna que relaciona 'url_qr' con 'qr_codes'
        
        // Verificar si la actualización fue exitosa
        if (resultUrlQr.error) {
            console.error("Error al actualizar url_qr:", resultUrlQr.error);
            return;
        }
        //console.log("se actualizo el qr con los siguientes datos <br> nombre:",nombreQr,"<br> url:",url_qr,"<br> activo:",activo)
        
        return true;
    }


    const handleDeleteQr= async (id_qr)=>{
        const session_user= supabase.auth.getSession();
        const user_id= (await session_user).data.session.user.id
        //console.log("borrando qr",id_qr)

            const result= await supabase.from('qr_codes')
            .update({eliminado:true})
            .eq('user_id',user_id)
            .eq('id',id_qr)
            .then((result) => {

                if(result.status==204){
                    //console.log("se deshabilitó el qr")
                    setQR(qr_code.filter((qr)=>qr.id!=id_qr))
                  

                }else
                {
                    //console.log(result)
                }
            })
      
    }
    const generateQRImage = async () => {
        const apiUrl = process.env.REACT_APP_URL_API + 'v1/generate-qr';
        //console.log("llamando a la api", apiUrl);
        
        try {
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    size: 400,
                })
            });
    
            const data = await response.json();
            //console.log(data);
            return data;
        } catch (error) {
            console.error("Error al generar la imagen QR:", error);
            throw error; // Esto es importante para que puedas manejar el error en la función que llama a generateQRImage
        }
    }

    const generateURLandCode=async (id_qr,url_qr)=>{
        try{
            const resultado_object={};
            //console.log("generando url y codigo")
            const qr_status_creation= await generateQRImage().
            then((result) => {
                //console.log(result);
                if(result.status==200){
                    //console.log("se creo el codigo")
                    //console.log("id_qr",result.qrCodeId);
                    //console.log("url_qr",result.qrCodeFileName)
                    //console.log("url_qr",result.url)
                    resultado_object.qrCodeId=result.qrCodeId;
                    resultado_object.qrCodeFileName=result.qrCodeFileName;
                    resultado_object.url=result.url;
                    
                }
            }
            ).catch((error) => {
                //console.log(error);
            });


            const result = await supabase.from('url_qr').insert({
                qr_id: id_qr,
                url: url_qr,
                id_imagen: resultado_object.qrCodeId,
                nombre_imagen: resultado_object.qrCodeFileName,
            }).then((result) => {
                //console.log(result);
                if(result.status==201){
                    //console.log("se creo el link")
                }
            }).catch((error) => {
                //console.log(error);
            });
        }
        catch(error){
            //console.log(error);
        }

    
    }
    const createCustomFolder = async (user_id) => {
        try
        {
            //console.log("creando carpeta")
            const result = await supabase.from('folders').insert({
                user_id: user_id,
                name: "Sin Carpeta",
                description:"Carpeta por defecto"
            }).select().then((result) => {
                //console.log(result);
                if(result.status==201){
                    //console.log("se creo la carpeta")
                    //console.log(result.data[0].id)
                    return result.data[0].id
                }
            }).catch((error) => {   
                //console.log(error);
            });
        }catch(error){
            //console.log(error);
        }
    }
    const createQr = async (nombreQr,urlQr,folder) => {
        setAdding(true)
        try
        {
            let session_user= supabase.auth.getSession();
            let user_id= (await session_user).data.session.user.id
            let folder_id= await (folder==null|| folder=="") ?  createCustomFolder(user_id) : folder
            const result = await supabase.from('qr_codes').insert({
                user_id: user_id,
                nombre: nombreQr,
                folder_id:null,
            }).select().then((result) => {
                //console.log(result);
                if(result.status==201){
                    generateURLandCode(result.data[0].id,urlQr);
                }
            }).catch((error) => {   
                //console.log(error);
            });
        }catch(error){
            //console.log(error);
        }
       finally{
        setAdding(false)
       }
    }
    const createReminder = async (name,clientId,description,dateFirstNotification,periocity,timeNotification,todayNotification,copyClientNotification,emailClientNotification) => {
        setAdding(true)

    }
    
    const getAllFolders = async () => {

        //query qr count

        let array_folders=[]
        let array_qr=[]

        //console.log("obteniendo folders")
        const session_user= supabase.auth.getSession();
        const user_id= (await session_user).data.session.user.id

        


        const result = await supabase.from('folders').select('*').eq("user_id",user_id).then((result) => {
               //console.log(result);
                if(result.status==200){
                    //setFolders(result.data)
                    array_folders=result.data
    
                }
            }).catch((error) => {
                //console.log(error);
        });
        //const result = await supabase.from('qr_codes').select('*,url_qr:url_qr(url),url_imagen:url_qr(nombre_imagen)').eq("user_id",user_id).eq("id",id_qr).
        const result_qr_count = await supabase.from('qr_codes').select('*').eq("user_id",user_id).then((result) => {
            //console.log(result);
            if(result.status==200){
                ////console.log("se obtuvo todos los codigos qr")
               // //console.log(result.data)
                array_qr=result.data
                // setFolders(result.data)
            }
        }).catch((error) => {
            //console.log(error);
        });

        array_folders.forEach((folder)=>{
            folder.qr_count=array_qr.filter((qr)=>qr.folder_id==folder.id).length
        })
        //console.log(array_folders)
        setFolders(array_folders)

    }
    const createFolder = async (name,description) => {
        setAdding(true)
        try
        {
            let session_user= supabase.auth.getSession();
            let user_id= (await session_user).data.session.user.id
           
            //console.log("creando carpeta")

            const result = await supabase.from('folders').insert({
                user_id: user_id,
                name: name,
                description:description
            }).select().then((result) => {
                //console.log(result);
                if(result.status==201){
                    //console.log("se creo la carpeta")
                }
            }).catch((error) => {   
                //console.log(error);
            });
     

        }catch(error){
            //console.log(error);
        }
       finally{
        setAdding(false)
       }

    }

    const createClient= async (nombre,correo,mensaje)=>{
        setAdding(true)
        try
        {
            let session_user= supabase.auth.getSession();
            let user_id= (await session_user).data.session.user.id
            

            const result = await supabase.from('user_clients').insert({
                user_id: user_id,
                name: nombre,
                mail:correo,
                mensaje:mensaje
            }).select().then((result) => {
                //console.log(result);
                if(result.status==201){
                    //console.log("se creo el cliente")
                }
            }).catch((error) => {   
                //console.log(error);
            });
     

        }catch(error){
            //console.log(error);
        }
       finally{
        setAdding(false)
       }

    }
    
    return(
        <QrContext.Provider value={{setUserIdGlobal,user_id_global,getUserPlan,setUserProfile,getUserProfile,folderInfo,getFolderData,qr_code,qr_codes,currentQr,getAllQr,createQr,adding,handleDeleteQr,getDataQr,updateQR,createClient,getAllClients,clients,getAllFolders,foldersAll,createFolder,getAllQrByFolder}}>
            {children}
        </QrContext.Provider>
    )
}