import react from 'react';
import {Container,Row,Col,Button,Stack} from 'react-bootstrap';
import MenuLateralAdmin from './templates/menuLateralAdmin';
import QrBox from './templates/qr-box'
import Form from 'react-bootstrap/Form';
import {useState} from 'react';
import { useQR } from '../context/QrContext';
import {useNavigate} from 'react-router-dom';
import { validateEmail } from '../utils/utils';
import Alert from 'react-bootstrap/Alert';
import Header from './templates/header';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';


function AddQr(){
    const navigate= useNavigate();
    const {createClient}= useQR()
    const [nameClient, setNameClient] = useState('');
    const [mailClient, setMailClient] = useState('');
    const [messageClient, setMessageClient] = useState('');
    const [dateMessage, setDateMessage] = useState('');
    const [mensajeBorrar, setMensajeBorrar] = useState(false);
    const handleClose = () => setMensajeBorrar(false);
    const handleShow = () => setMensajeBorrar(true);


    const [show, setShow] = useState(false);
    
    //nombre validation 
    const validateNameClient = (name) => {
        return nameClient.length >= 3;
    }
    //mailClient Validation 
    const validateMailClient = (mail) => {
        return validateEmail(mail);
    }
    //messageClient Validation
    // const validateMessageClient = (message) => {
    //     return message.length >= 3;
    // }
    // //dateMessage Validation
    // const validateDateMessage = (date) => {
    //     return date.length >= 3;
    // }

    const {createQr,adding} =useQR()

    
    const clearData=()=>{
        setDateMessage('')
        setMessageClient('')
        setMailClient('')
        setNameClient('')
        setMensajeBorrar(false)
    }
    const handleCreateClient = async (e) => {
        e.preventDefault();
        console.log("creando cliente")
        console.log(nameClient,mailClient)
        //validate fields
        if (!validateNameClient(nameClient) || !validateMailClient(mailClient)) {
            // Mostrar un mensaje al usuario o manejar el error de validación
            console.log("falta algo")
            setShow(true)
            
            return;
        }
        
        try {
            const result = await createClient(nameClient,mailClient)
            console.log(result)
        }
        catch(error){
            console.error('No se pudo crear el cliente', error);
        }
        
        
       

        setMensajeBorrar(true)
       // createQr(nombreQr,urlQr)

        setShow(false)
        setMailClient('')
        setNameClient('')

        //navigate('/admin')
    }
    return(
        <>
        
        
        <Header>
        </Header>
        <Container fluid >
      <Row>
        <Col xs={4} xl={2} className="menu-lateral">
            <MenuLateralAdmin/>
        </Col>
        <Col xl={10} xs={8} className="panel-derecho">

            <Container>
                <Row>
                    <Col xl={2} xs={12}>
                        <Stack gap={1} className="mt-5 m-3">
                        
                        <div className='boton'  onClick={()=>navigate('/admin/clients')}> Volver</div>
                        </Stack>
                    </Col>
                </Row>
                <Row>
                    <Col xl={6} xs={12}>
                    <Container>
                    <Form>
                        <Form.Group className="mb-3" >
                            <Form.Label>Nombre</Form.Label>
                            <Form.Control type="text"  id="nameClient" placeholder="Nombre del cliente" onChange={e=> setNameClient(e.target.value) } />
                            <Form.Label>Correo</Form.Label>
                            <Form.Control type="text" name= "emailClient" placeholder="Email del cliente" onChange={e=>setMailClient(e.target.value)}/>

                            {/* <Form.Label>Mensaje hacia el cliente</Form.Label>
                            <Form.Control as="textarea" type="textarea" name= "messageClient" placeholder="Mensaje hacia el cliente" onChange={e=>setMessageClient(e.target.value)}/>
                            <Form.Label>Fecha de recordatorio de pagos</Form.Label>
                            <Form.Control type="date" name= "dateClient" placeholder="07/10/23" onChange={e=>setDateMessage(e.target.value)}/> */}
                            
                        </Form.Group>
                        <Form.Group className="mb-3" id="addClient">
                              
                                <div className='boton' disabled={adding} onClick={ handleCreateClient}>{adding? "Creando Cliente":"Crear Cliente"}</div>
                        </Form.Group>
                        {show ? (
                        <Alert variant="danger" onClose={() => setShow(false)} dismissible>
                        <Alert.Heading>Ups, falta algo</Alert.Heading>
                        <p>
                            Revisa todos los datos ingresados
                        </p>
                    </Alert>):""}
                        

                       
                        </Form>
                    </Container>
                    
                 </Col>
                 </Row>
            </Container>


        </Col>
      </Row>

    </Container>
    <Modal  size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered show={mensajeBorrar} onHide={handleClose} 
     >
    <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Cliente Creado</Modal.Title>
        </Modal.Header>
        <Modal.Body>Cliente Creado, ¿desea agregar otro?</Modal.Body>
        <Modal.Footer>
      
          <div className='boton eliminar' onClick={()=>clearData()} >Si</div>
          <div className='boton eliminar' onClick={()=>navigate('/admin/clients')} >No</div>
        
        </Modal.Footer>
      </Modal>
    </>
    )
}

export  default AddQr;