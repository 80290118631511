import { Container, Row,Col } from "react-bootstrap";
import Image from 'react-bootstrap/Image';


const slider = () => {
    return (
        <Container  fluid="xxl" className="contenedor-slider-home">
            <Row>
                <Col xs={12} xl={6}>
                    <Container className="contenedor-texto-home font-sans	">
                    <h1> Códigos QR Administrables para tus clientes </h1>
                    <span>
                   Mr. Wolf es una plataforma que te permite crear códigos QR para tus clientes, con la posibilidad de cambiar el destino de los códigos en cualquier momento.



                    </span>

                    </Container>
                    
                   
                </Col>
                <Col xs={12} xl={6}>
                        <div className="contenedor-slide-home ">

                            <div className="gradientUp"></div>
                            <Image src="/img/site/assets/65ca29e72617ece37c6521de_Frame_1.png" fluid />


                        </div>
                </Col>

            </Row>

        </Container>
    )
    }

export default slider;