import {React} from 'react';
import { supabase } from '../supabase/client';
import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import MenuLateralAdmin from './templates/menuLateralAdmin';
import Container from 'react-bootstrap/Container';
import { Stack ,Button, Row,Col} from 'react-bootstrap';
import {useQR} from '../context/QrContext';
import QrBox from './templates/qr-box'
import { Link } from 'react-router-dom';
import  Header from './templates/header';
import { useState } from 'react';
import Card from 'react-bootstrap/Card';
import {formatDateToDMYHM} from '../utils/utils'; 



function Folders(){
    
    //const {qr_code, getAllQr} = useQR();
    //get all folders
    const {foldersAll, getAllFolders} = useQR();

    const navigate= useNavigate();
    const [mostrarHabilitados, setMostrarHabilitados] = useState(true);
    const toggleMostrarHabilitados = () => {
        setMostrarHabilitados(true);
      };
    const toggleMostrarPendientes = () => {
        setMostrarHabilitados(false);
      }
    
 //  console.log(qr_code)
    useEffect(() => {
    //cargar qr del usuario
    getAllFolders();    
    }, [])

    return(
        <>
      <Header>
        </Header>
        
        <Container fluid >
      <Row>
        <Col xs={4} xl={2} className="menu-lateral">
            <MenuLateralAdmin onToggleMostrarHabilitados={toggleMostrarHabilitados} onToggleMostrarPendientes={toggleMostrarPendientes} />
        </Col>
        <Col xl={10} xs={8} className="panel-derecho">

            <Container>
                <Row>
                    <Col xl={3} xs={12}>
                        <Stack gap={1} className="mt-5 mb-2">
                        {/* <Button variant="primary" className ="boton blanco" onClick={()=>navigate("qr")}>Crear Nuevo Codigo QR</Button> */}
                        <div className='boton ' onClick={()=>navigate("add")}> Crear Nueva Carpeta</div>
                        {/* <Link to="/admin/qr" className="btn btn-primary">Crear Nuevo Codigo QR</Link> */}
                        </Stack>
                    </Col>
                </Row>
                <Row>
                    

                 

             
                        {
                            foldersAll.map((fo)=>{
                            return <Col key={fo.id} xl={2} xs={6}>
                                      <Card style={{ width: '100%' }} className='folder-box-container' onClick={()=> navigate("/admin/folders/"+fo.id)}>
                                                    <div className='contenedor-imagen-folder'>
                                                    <Card.Img variant="top" src="/img/site/assets/folder_icon.png" className='img-fluid' />
                                                   
                                                    </div>
                                                    
                                                    <Card.Body>
                                                        <Card.Title className='titulo'>{fo.name} | ({fo.qr_count})</Card.Title>
                                                        <Card.Text className='descripcion'>
                                                        {fo.description}
                                                        </Card.Text>
                                                        <Card.Text>
                                                        {formatDateToDMYHM(fo.created_at)}
                                                        </Card.Text>

                                                    </Card.Body>
                                                    </Card>
  
                                </Col>
                      })}

                        

                 
                 
                 </Row>
            </Container>


        </Col>
      </Row>

    </Container>
    </>
    )
}

export default Folders;
