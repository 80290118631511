import react from 'react';
import {Container,Row,Col,Button,Stack} from 'react-bootstrap';
import MenuLateralAdmin from './templates/menuLateralAdmin';
import QrBox from './templates/qr-box'
import Form from 'react-bootstrap/Form';
import {useState} from 'react';
import { useQR } from '../context/QrContext';
import {useNavigate} from 'react-router-dom';
import { validateUrl } from '../utils/utils';
import Alert from 'react-bootstrap/Alert';
import Header from './templates/header';
import Toast from 'react-bootstrap/Toast';
import {useEffect} from 'react';

function AddQr(){
    const [showToast, setShowToast] = useState(false);

    const toggleShowToast = () => setShowToast(!showToast);


    const navigate= useNavigate();
    const [nombreQr, setNombreQr] = useState('');
    const [urlQr, setUrlQr] = useState('');
    const [show, setShow] = useState(false);
    
    //nombre validation 
    const validateNombreQr = (nombre) => {
        return nombre.length >= 3;
    }
    //url validation
    const validateUrlQr = (url) => {
        return validateUrl(url);
    }

    const {createQr,adding,foldersAll,getAllFolders} =useQR()
    const [nombreFolder, setNombreFolder] = useState('');
    const [folder, setFolder] = useState('');
    
    const handleSetFolder = (e) => {
        setFolder(e.target.value)
    }

    useEffect(() => {
      //  getAllFolders()
    }, [])

    const handleCreateQr = async (e) => {
        e.preventDefault();

        if (!validateNombreQr(nombreQr) || !validateUrlQr(urlQr)) {
            // Mostrar un mensaje al usuario o manejar el error de validación
          
            setShow(true)
            return;
        }
        createQr(nombreQr,urlQr,folder)
        setShowToast(true)
        setShow(false)
        setNombreQr('')
        setUrlQr('')
        //navigate('/admin')
    }
    return(
        <>
        
        
        <Header>
        </Header>
        <Container fluid >
      <Row>
        <Col xs={4} xl={2} className="menu-lateral">
            <MenuLateralAdmin/>
        </Col>
        <Col xl={10} xs={8} className="panel-derecho">

            <Container>
                <Row>
                    <Col xl={2} xs={12}>
                        <Stack gap={1} className="mt-5 m-3">
                        
                        <div className='boton'  onClick={()=>navigate(-1)}> Volver</div>
                        </Stack>
                    </Col>
                </Row>
                <Row>
                    <Col xl={6} xs={12}>
                    <Container>
                    <Form>
                        <Form.Group className="mb-3" >
                            <Form.Label>Nombre</Form.Label>
                            <Form.Control type="text"  id="nombre_qr" placeholder="Nombre de tu qr" value={nombreQr} onChange={e=>setNombreQr(e.target.value) } />
                            <Form.Label>URL</Form.Label>
                            <Form.Control type="text" name= "url_qr" placeholder="Url de qr"  value={urlQr} onChange={e=>setUrlQr(e.target.value)}/>
                            {/* <Form.Label>Selecciona una carpeta</Form.Label> */}
                            {/* <Form.Select className="mt-1" aria-label="Default select example" value={folder} onChange={handleSetFolder}>
                                <option value=''>-</option>
                              {foldersAll.map((folder)=>{

                                return <option key={folder.id} value={folder.id}>{folder.name}</option>
                                
                                })}
                            </Form.Select> */}
                        </Form.Group>
                        <Form.Group className="mb-3" id="crearQrBtn">
                              
                                <div className='boton' disabled={adding} onClick={ handleCreateQr}>{adding? "Creando QR":"Crear QR"}</div>
                        </Form.Group>
                        {show ? (
                        <Alert variant="danger" onClose={() => setShow(false)} dismissible>
                        <Alert.Heading>Ups, falta algo</Alert.Heading>
                        <p>
                        Ingresa un nombre y una url valida
                        </p>
                    </Alert>):""}
                        
                    <Toast show={showToast} onClose={toggleShowToast} animation={true} bg="success">
          <Toast.Header>
            <img
              src=""
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">Qr agregado</strong>
            <small></small>
          </Toast.Header>
          <Toast.Body className='text-white'>Listo, creaste el codigo correctamente</Toast.Body>
        </Toast>
                       
                        </Form>
                    </Container>
                    
                 </Col>
                 </Row>
            </Container>
                            


        </Col>
      </Row>

    </Container>
    </>
    )
}

export  default AddQr;