import {react} from 'react';

function NotFound(){
    return(
        <div>
            <h1>404</h1>
        </div>
    )
}

export default NotFound;