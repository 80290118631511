import React from 'react';
import { Container,Row,Col,Image } from 'react-bootstrap';
import "@fontsource/ubuntu";




function Features () {
    return (
       
       <>
  
         <Container className="contenedor-texto-slide font-sans mt-5 mb-5	">
                    <h1>Simple, útil y poderoso </h1>
                
        </Container>
      <Container className=' mt-5'>
            <Row>
                <Col xs={12} xl={4} >
                   <div className='contenedor-features mb-2'> 
                            <div className='logo-feat'> 

                                        <Image src="/img/site/assets/65cda77580554dc7bf3d7429_Service_1.png" fluid />

                            </div>
                            <div className='texto-feat'>
                                <h3>Scan ilimitados</h3>
                                     <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin luctus lacus a orci feugiat, quis auctor libero iaculis.</p>
                                </div>
                   </div> 
                </Col>
                <Col xs={12} xl={4}>
                    <div className='contenedor-features mb-2'> 
                                <div className='logo-feat'> 

                                        <Image src="/img/site/assets/65cda775ffa57e6270709834_Service_2.png" fluid />

                                </div>
                                <div className='texto-feat'>
                                <h3>Estadísticas de uso</h3>
                                     <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin luctus lacus a orci feugiat, quis auctor libero iaculis.</p>
                                </div>
                    </div>        
                </Col>
                <Col xs={12} xl={4}>
                     <div className='contenedor-features mb-2'> 
                                <div className='logo-feat'> 

                                        <Image src="/img/site/assets/65cda775abb423cfdb57dac1_Service_3.png" fluid />

                                </div>
                                <div className='texto-feat'>
                                <h3>Crea qr con un csv</h3>
                                     <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin luctus lacus a orci feugiat, quis auctor libero iaculis.</p>
                                </div>
                     </div>       
                </Col>

            </Row>
      </Container>
      </>
    )
}

export default Features;